import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/SEO/seo"
import Image from "../components/Images"
import { Text } from "../components/Text/Text"
import { Col, Row } from "../components/Grid/Grid"
import { SocialLinks } from "../components/SocialLinks/SocialLinks"

const IndexPage = () => (
  <Layout>
    <SEO title="NERDZ.js - we do web"/>
    <div style={{
      padding: '4em 0'
    }}>
      <Row>
        <Col flex justifyCenter size={6} sizeXs={12}>
          <Image.LogoJS />
        </Col>
        <Col size={6} sizeXs={12}>
          <div style={{
            padding: '5em 0'
          }}>
            <Text>
              JavaScript software studio - we implement business solutions to help your business grow.
            </Text>
            <SocialLinks />
          </div>
        </Col>
      </Row>
    </div>
  </Layout>
)

export default IndexPage

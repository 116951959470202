import * as React from "react"
import styled from "styled-components"
import { Colors } from "../../utils/constants/colors.constants"

export const List: React.FC = ({ children }) => (
  <ListWrapper>{children}</ListWrapper>
)

export const ListElement: React.FC = ({ children }) => (
  <Element>{children}</Element>
)

const ListWrapper = styled.ul`
    margin: 0;
    list-style: none;
    display: flex;
`

const Element = styled.li`
    font-family: 'Rajdhani';
    color: ${Colors.white};
    margin-right: 1em;
    a {
      color: ${Colors.white};
      text-decoration: none;
    }
`

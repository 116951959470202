import styled from 'styled-components'

type Col = {
  size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  // Mobile column size (> 960px)
  sizeXs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  flex?: boolean
  alignCenter?: boolean
  justifyCenter?: boolean
  justifyBetween?: boolean
  justifyStart?: boolean
  justifyEnd?: boolean
  column?: boolean
}


export enum MediaQuery {
  Mobile = '@media only screen and (max-width: 560px)',
  Desktop = '@media only screen and (min-width: 960px)',
}

export const Container = styled.div`
    max-width: 1260px;
    margin: 0 auto;
    padding: 0 10px 0 10px;
    @media only screen and (max-width: 960px) {
    }
`

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const Col = styled.div<Col>`
    padding: 0 4px 0 4px;
    position: relative;
    ${props => props.flex && 'display: flex'};
    ${props => props.flex && props.alignCenter && 'align-items: center'};
    ${props => props.flex && props.justifyCenter && 'justify-content: center'};
    ${props =>
  props.flex && props.justifyStart && 'justify-content: flex-start'};
    ${props => props.flex && props.justifyEnd && 'justify-content: flex-end'};
    ${props =>
  props.flex && props.justifyBetween && 'justify-content: space-between'};
    ${props => props.flex && props.column && 'flex-direction: column'};
    flex: ${props => props.size};
    flex-basis: ${props => (props.size / 12) * 100}%;
    ${MediaQuery.Mobile} {
        padding: 0;
        ${props => props.sizeXs && `flex-basis: ${(props.sizeXs / 12) * 100}%;`}
    }
`

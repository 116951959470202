import * as React from "react"
import { List, ListElement } from "../List/List"
import { graphql, useStaticQuery } from "gatsby"

export const SocialLinks = () => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
        allSocialLink {
          nodes {
            id
            name
            href
        }
      }
    }
  `)

  return (
      <List>
        <ListElement>
          <strong>CONTACT</strong>
        </ListElement>
        {data.allSocialLink.nodes.map((link) => (
          <ListElement>
            <a
              target={'_blank'}
              rel={'noreferrer'}
              href={link.href}>
              {link.name}
            </a>
          </ListElement>
        ))}
      </List>
  )
}
